.aaPhoneCountryCode, .aaMaterialCountryCodes {
    position: relative;
    .selectbox-overlay {
        position:absolute;
        left:0;
        right:0;
        top:0;
        bottom:0;
    }
    .selectbox-label {
        position: absolute;
        bottom: 22px;
        left: 5px;
        z-index: -1;
    }
    select[readonly] {
        background-color: $white;
        border-color: $gray;
    }
    select[size] {
        position:absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 0;
        z-index: -1;
    }
    .is-error {
        select[readonly] {
            border-color: $darkRed;
        }
    }
    &.-active {
        overflow: initial;
        .selectbox-label {
            z-index: 1;
            bottom: 0;
            left: 0;
        }
        select[size] {
            width: auto;
            height: auto;
        }
    }
    &.-focus {
        select[readonly] {
            outline: 1px solid $brandBlue;
            border-color: $brandBlue;
        }
        select[size] {
            border-color: inherit;
            outline: none;
        }
    }
}
.aaMaterialCountryCodes {
    select[readonly] {
        position: relative;
        border-color: $lightGray;
    }
    select[size]:disabled {
        opacity: 0;
    }

    &.-active {
        select[size] {
            color: $darkGray;
            top: 52px;
            bottom: auto;
        }
    }
}
