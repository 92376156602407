table {
    &.airport {

        .airport-city {
            width: 25%;
        }

        .airport-name {
            width: 50%;
        }

        .airport-code {
            width: 25%;
            text-align: center;
        }
    }
}

@include respond-to(phone) {
    .aaAirportLookup {
        display: none;
    }
}
