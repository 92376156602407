@media screen and (max-width: 1980px) {
  .airlineName {
    font-size: 16px;
  }
  .fltNumAndTimes {
    font-size: 24px;
  }
  .currentFlightStatus {
    font-size: 24px;
  }
  .flightStatusPad {
    padding-top: 49px;
    img {
      margin-right: 10px;
      vertical-align: bottom;
    }
  }
  td.sepText {
    text-align: left;
  }
  .wifi {
    padding-left: 30px;
  }
  #mobileFlightStatus {
    display: none;
  }
}

@media screen and (max-width: 480px) {
  h1 {
    margin: 10px 0 10px;
  }
  .currentFlightStatus.cancelled {
    background-color: #C30019;
    color: white;
    padding: 7px 0px 5px 10px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .currentFlightStatus.irregular {
    background-color: #d14904;
    color: white;
    padding: 7px 0px 5px 10px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .currentFlightStatus.normal {
    background-color: #008712;
    color: white;
    padding: 7px 0px 5px 10px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .currentFlightStatus.unknown {
    background-color: #627A88;
    color: white;
    padding: 7px 0px 5px 10px;
    margin-left: -10px;
    margin-right: -10px;
  }
    .currentFlightStatus.darkGrey {
    background-color: #36495a;
    color: white;
    padding: 7px 0px 5px 10px;
    margin-left: -10px;
    margin-right: -10px;
  }
  .flightHeader {
    border-bottom: 0px solid #ffffff;
  }
  .flightStatusPad {
    padding-top: 0px;
  }
  .departAlign {
    padding-top: 0px;
  }
  td.sepText {
    text-align: center;
  }
  .hrPad {
    width: 50px;
  }
  .stopCityTextMove span {
    padding-left: 10px;
  }
  #mainFlightStatus {
    display: none;
  }
  #mobileFlightStatus {
    display: block;
    margin-top: 20px;
  }
  .mobileAirlineInfo {
    padding-bottom: 20px;
    img {
      margin-right: 10px;
    }
  }
  .mobileFlightStatusText {
    padding-bottom: 20px;
  }
  .mobileDepartArriveCols {
    width: 290px;
    padding: 0;
    margin: 0;
  }
  .mobileStopCity {
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .padbtm10 {
    padding-bottom: 10px;
  }
  .leftDepartInfo {
    /*   margin-left: -15px; */
  }
  .wifi {
    padding: 0px;
  }
}

.fleft {
  float: left;
}

.fright {
  float: right;
}

.clear {
  clear: both;
}

.h3margin {
  margin-top: -20px;
}

.hrPad {
  padding-right: 10px;
}

table {
  width: 100%;
}

thead {
  background-color: #EBEFF0;
  border-bottom: 1px solid #d0dae0;
}

th {
  font-family: "AmericanSans Medium", "AmericanSans", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-size: 15px;
  color: #36495a;
}

th.flight {
  padding-left: 10px;
  height: 40px;
}

th.colWidth,
td.colWidth {
  width: 215px;
}

.fltNumAndTimes {
  font-family: "AmericanSansLight-ie", "AmericanSansLight", Helvetica, Arial, sans-serif;
  font-weight: normal;
  font-style: normal;
  padding-bottom: 10px;
  padding-right: 10px;
}

.flightStatusPad {
  vertical-align: top;
  padding-left: 10px;
  width: 334px;
  height: 225px;
}

.departAlign {
  vertical-align: top;
  padding-top: 20px;
}

.timeLabel {
  padding-bottom: 15px;
}

.flightLinks {
  padding-bottom: 15px;
}

.clear-border--table-row {
  border: none;
}

.arriveAlign {
  vertical-align: top;
  padding-top: 20px;
}

.linksColumn {
  vertical-align: top;
  padding-top: 20px;
}

.arriveAlignOA {
  vertical-align: top;
  padding-top: 20px;
}

.termGateInfo {
  font-size: 14px;
  line-height: 20px;
  padding-bottom: 20px;
}

.arriveDepartAlert {
  padding: 10px 0px;
}

hr.dash {
  border: 0;
  border-top: .075em dashed #d0dae0;
  margin: 0;
}

td.sepText {
  padding: 10px 0px;
}

hr.leftDash {
  border: 0;
  border-top: .075em dashed #d0dae0;
  margin: 0px 0px;
  margin-bottom: 0.5em;
}

hr.rightDash {
  border: 0;
  border-top: .075em dashed #d0dae0;
  margin: 0px 0px;
}

.stopCityTextMove {
  margin-top: -0.8em;
}

.stopCityTextMove span {
  display: inline-block;
  background-color: white;
  padding-right: 10px;
  font-family: "AmericanSans", Helvetica, Arial, sans-serif;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
  white-space: nowrap;
}

#mapDisplay {
  height: 425px;
}
