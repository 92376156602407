// compass mixins
@import "compass/css3";

// required partials
@import "v3/partials/variables";
@import "v3/partials/mixins";

@import "partials/search-responsive";

@import "v3/partials/widget-phone-country-code";
@import "v3/partials/tables";
@import "v3/partials/flight-status";

.searchBy {
  padding-top: 3px;
  font-family: $standardFontFamily;
  @include font-size(14);
  margin-right: 10px;
}

.require {
  margin: 23px 0;
}

.searchSection {
  margin-top: 8px;
}

/*
.flightSection {
	margin-top: 8px;
}
*/

.wifi {
  color: $brandBlue;
}

/* Flight Color Statuses */
.cancelled {
  color: $red;
}

.irregular {
  color: $orange;
}

.normal {
  color: $green;
}

.unknown {
  color: $mediumGray;
}

.darkGrey {
  color: $darkGray;
}

/*@include respond-to(phone_landscape) {
	.currentFlightStatus.green {
	    background-color: $green;
	    color: $white;
	    padding: 7px 0px 5px 10px;
	    margin-left: -10px;
	    margin-right: -10px;
	}
}*/

.opDisclosure {
  font-size: $sm-size;
  font-family: $standardFontFamily;
}

.cityCode {
  font-family: $sansFontFamilyRegular;
  font-size: $h5-size;
}

.airlineName {
  font-family: $standardFontFamily;
  white-space: nowrap;
}

.currentFlightStatus {
  padding-top: 55px;
  font-family: $sansFontFamilyRegular;
}

.current-location-btn {
  @include icon-button(33px, 8px);
}

@import "v3/partials/airport-lookup";
