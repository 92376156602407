@include respond-to(phone) {
	#aa-content-frame > h1 {
		margin-top: 0;
		margin-bottom: 15px;
	}

	.section + .section {
		background: none;
		margin: 20px 0 0 0;
		padding: 0;
	}

	.submitBtn {
		margin-top: 10px !important;
	}

	.airport-search {
		display: none;
	}

	.route-link {
		display: none;
	}

	.btn {
		@include font-size(24);
	}

	.current-location-btn {
        @include icon-button(33px, 8px);
	}
}
